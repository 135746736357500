// Colors
$primary: rgb(190, 5, 117);
$link: rgb(32, 63, 182);
$ig-blue: $link;
$info: #61afef;
$warning: #d19a66;
$success: #89ca78;
$family-sans-serif: 'Nunito';

// Nunito
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');
