.search-modal {
  padding: 0rem 0.5rem;
  overflow-x: hidden;
}

// Search results container
.search-container {
  min-height: 100vh;
}

.search-modal .search-box {
  margin-top: 20vh;
}
