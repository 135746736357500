.print-line-indented {
  margin-left: 10px;
  text-indent: -10px;
  display: inline-block;
}

.print-paragraph {
  margin-bottom: 9px !important;
  line-height: 19px;
  font-size: 10.1pt;
  color: darken($body-color, 10%);
}

.print-paragraph strong {
  color: darken($body-color, 10%);
}

.print-paragraph ul {
  margin-top: -6px;
}

.print-paragraph .tag {
  margin-bottom: 5px;
  margin-right: 5px !important;
  padding: 0px 5px;
  height: 20px;
  font-size: inherit;
  color: darken($body-color, 10%);
}
