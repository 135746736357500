.city {
  background-image: linear-gradient(45deg, rgba(32, 63, 182, 0.7), rgba(190, 5, 117, 0.5)),
    url('./../../../assets/pgh.jpg') !important;
  background-size: cover;
  background-position: center center;
  padding-top: 10px;
  padding-bottom: 70px;
}

.city .button:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.fade-bottom {
  mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 1) 5%,
    rgba(0, 0, 0, 1) 15% 85%,
    rgba(0, 0, 0, 0) 95%
  );
}
