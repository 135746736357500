@import 'node_modules/bulmaswatch/flatly/_variables';

@import 'vars';

// Other Bulma imports
@import 'bulma';
@import '~bulma-tooltip';
@import 'node_modules/bulma-badge/dist/css/bulma-badge';
@import 'node_modules/bulmaswatch/flatly/_overrides';

// Black background everywhere
html,
.blackened {
  @extend .has-background-black;
}

// Gradient
.gradient {
  background: linear-gradient(45deg, $link, $primary);
}

// Gradient text
.gradientbg {
  background: linear-gradient(45deg, lighten($link, 25%), lighten($primary, 25%));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

// Gradient block {
.colorbar {
  height: 5px;
  width: 258px;
  margin: auto;
  margin-top: 3px;
  @extend .gradient;
}

// Bolder title fonts
.fancy .title {
  font-weight: bold;
}

// Black background links
%fancy-link {
  color: inherit;
  background-image: linear-gradient(to bottom, transparentize($grey, 0.5) 50%, transparent 50%);
  background-position: 0 1.02em;
  background-repeat: repeat-x;
  background-size: 2px 6px;
  text-decoration: none !important;
}

%fancy-link-hover {
  color: lighten($link, 25%) !important;
}

.hero.is-black p a,
.underlink {
  @extend %fancy-link;
}

.hero.is-black p a:hover,
.underlink:hover,
.hoverable:hover {
  @extend %fancy-link-hover;
}

.hero.is-black a:not(.button):not(.dropdown-item):not(.tag) {
  color: inherit;
}

// Light blue link hover color
a:hover {
  color: $info;
}

a.tag:hover {
  text-decoration: none;
}

// Spacing after boxes
.box:not(:last-child) {
  margin-bottom: 1.25rem;
}

// Do round section headers
.rounded {
  border-radius: $radius-large;
  @extend .has-shadow;
}

// Box shadow
.has-shadow {
  box-shadow: 0 0.5rem 1rem rgba(50, 50, 50, 0.25);
}

// Fix links in tabs
.tabs > a {
  @extend li;
}

// Grow on hover
.hover-expand:hover {
  transform: scale(1.1);
}

// 100vh hero
.hero-body-centered {
  display: flex;
  align-items: center;
}

// Domain-specific styles
@import 'base/core/styles';
@import 'base/dropdown/styles';
@import 'base/footer/styles';
@import 'base/image/styles';
@import 'base/navigation/styles';
@import 'favorites/styles';
@import 'gallery/styles';
@import 'resume/core/styles';
@import 'resume/education/styles';
@import 'resume/printable/styles';
@import 'resume/search/styles';
@import 'resume/skills/styles';
