.print-title {
  border-bottom: 1px dotted $link;
  padding-bottom: 4px;
  margin-bottom: 8px !important;
  @extend .has-text-link;
}

.print-title:not(:first-child) {
  margin-top: 16px;
}
